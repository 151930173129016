import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const SuccesPage = () => (
  <Layout>
    <SEO title="Succes" />
    <h1>De email is succesvol verzonden</h1>
    <p>
      U kunt binnenkort een antwoord verwachten. <br />U krijgt een kopie van de
      email toegestuurd in uw mailbox.
    </p>
  </Layout>
)

export default SuccesPage
